/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {

        $('.dropdown-toggle').click(function () {
          var location = $(this).attr('href');
          window.location.href = location;
          return false;
        });

        $("#scoller").click(function () {
          $('html, body').animate({
            scrollTop: $(".tc-event-table-wrap").offset().top - 150
          }, 1000);
        });


        var $searchView = $('.menu-search-container');
        var $originalNav = $('.menu-header_nav-container');
        var $menu = $('.menu-item');
        var $fadeScreen = $('.fade-screen');
        var $searchButton = $("#searchButton");
        var $mobileSearchButton = $('.mobile-search-icon');
        var $logo = $('.logo-container');


        $('#searchButton, .mobile-search-icon, .fade-screen, .menu-search-close').on('click', function (e) {
          $searchView.toggleClass('active');
          $searchButton.toggleClass('hidden');
          $originalNav.toggleClass('hidden');



          if ($(window).width() < 990) {
            $('.navbar-toggle, .logo-container, .header-container .mobile-search-icon').toggleClass('hidden');




          }



          setTimeout(function () {
            $searchView.children().find('input').focus();
          }, 1100);

          $fadeScreen.toggleClass('visible');
          $menu.removeClass('is-closed');
          $menu.toggleClass('hidden');

          e.preventDefault();
        });

        $('.fade-screen,.menu-search-close').on('click', function (e) {
          $menu.toggleClass('is-closed');
          e.preventDefault();
        });







        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        $('#main-slider').on('init', function (event, slick) {
          $('.animated').addClass('activate fadeInUp');
        });

        $('#main-slider').slick({
          dots: true,
          speed: 1000,
          arrows: true,
          autoplay: true
        });

        $('#main-slider').on('afterChange', function (event, slick, currentSlide) {
          $('.animated').removeClass('off');
          $('.animated').addClass('activate fadeInUp');
        });

        $('#main-slider').on('beforeChange', function (event, slick, currentSlide) {
          $('.animated').removeClass('activate fadeInUp');
          $('.animated').addClass('off');
        });
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
